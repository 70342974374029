<!--
  个人中心
    + 各角色都有页面
    + 各角色都有基本资料
    + 普通机构存在机构入驻/挂靠
    + 服务机构存在服务集成商认证
 -->
<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="个人中心" />
    </div>
    <div class="panel_body">
      <div class="list_item">
        <div class="item_left">
          <div class="title">
            <i class="iconfont iconqiye"></i>
            <p>服务用户认证</p>
          </div>
          <div class="desc">
            普通机构进行入驻/挂靠审核后，可以再平台中发布服务产品
          </div>
        </div>
        <div class="item_right">
          <p
            v-if="!organization"
            @click="orgRegister">
            申请
          </p>
          <p
            v-if="canPending"
            style="color: #F1B221;"
            @click="orgRegister">
            审核中
          </p>
          <p
            v-if="canPass"
            style="color: #6AC244;"
            @click="orgRegister">
            已完成
          </p>
          <p
            v-if="canReject"
            style="color: rgb(226 60 57);"
            @click="orgRegister">
            已驳回
          </p>
        </div>
      </div>
      <div class="list_item" >
        <div class="item_left">
          <div class="title">
            <i class="iconfont iconfuwu"></i>
            <p>服务集成商认证</p>
          </div>
          <div class="desc">
            服务机构可以通过认证申请，获得更多操作权限
          </div>
        </div>
        <div class="item_right">
          <!-- 普通用户看不到 -->
          <template v-if="role !== globalVar.USER_ROLE_0">
            <p
              :style="{
                0: 'color: #3473E6;',
                1: 'color: #F1B221;',
                2: 'color: #6AC244;',
                3: 'color: rgb(226 60 57);'
              }[userInfo.certificationStatus]"
              @click="orgCertification">
              {{
                {
                  0: '申请认证',
                  1: '审核中',
                  2: '已完成',
                  3: '已驳回'
                }[userInfo.certificationStatus]
              }}
            </p>
          </template>
        </div>
      </div>
      <div class="list_item">
        <div class="item_left">
          <div class="title">
            <i class="iconfont iconlianjie"></i>
            <p>三方绑定</p>
          </div>
          <div class="desc">
            三方账号的绑定，便于您的快捷登录以及部分功能的正常使用。
          </div>
        </div>
        <div class="item_right">
          <div class="icon_box" :class="{'active': userInfo.snsStatus&1}" @click="handleTpaLogin('wechat', userInfo.snsStatus&1)">
            <i class="iconfont iconweixin1"></i>
          </div>
        </div>
      </div>
      <div class="list_item">
        <div class="item_left">
          <div class="title">
            <i class="iconfont iconmima"></i>
            <p>修改密码</p>
          </div>
          <div class="desc">
            安全性高的密码可以使账号更安全，建议您定期更换密码，设置一个包含数字、字母两种类型且长度超过8位的密码。
          </div>
        </div>
        <div class="item_right">
          <router-link to="/change-password" tag="a" target="_blank">修改</router-link>
        </div>
      </div>
    </div>

    <!-- 机构入驻选择身份dialog -->
    <select-identity
      :visible.sync="selectIdentityVisible">
    </select-identity>
  </div>
</template>

<script>
import * as api from '@/api'
import selectIdentity from './select-identity'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      // 机构入驻 -> 选择身份dialog
      selectIdentityVisible: false
    }
  },
  computed: {
    // 用户信息
    userInfo () {
      return this.$store.state.userInfo
    },
    // 法人非法人 入驻的状态
    registerStatus () {
      return this.userInfo.registerStatus
    },
    // 挂靠的状态
    dependStatus () {
      return this.userInfo.dependStatus
    },
    // 如果不存在机构 则未进行挂靠或入驻
    organization () {
      return this.$store.state.userInfo.organization
    },
    // 可以显示审核中
    // 机构角色， 0 - 挂靠关系 / 1 - 法人关系
    canPending () {
      return this.userInfo.organizationRole ? this.registerStatus === globalVar.REGISTER_STATUS_PENDING : this.dependStatus === globalVar.DEPEND_STATUS_PENDING
    },
    // 可以显示已完成
    canPass () {
      return this.userInfo.organizationRole ? this.registerStatus === globalVar.REGISTER_STATUS_PASS : this.dependStatus === globalVar.DEPEND_STATUS_PASS
    },
    // 可以显示已驳回
    canReject () {
      return this.userInfo.organizationRole ? this.registerStatus === globalVar.REGISTER_STATUS_REJECT : this.dependStatus === globalVar.DEPEND_STATUS_REJECT
    }
  },
  components: {
    selectIdentity
  },
  methods: {
    // 三方账号绑定
    handleTpaLogin (type, active) {
      if (active) {
        this.$message.info('已激活此三方账号！')
        return
      }
      const { origin } = location
      // 登录后要么跳到当前带着的回跳，要么是控制台
      api.getThirdPartyUrl(type, {
        redirect: `${origin}/third-party-callback/${type}/bind`
      }).then(res => {
        if (res.data.code === 0) {
          console.log('获取第三方登录地址', res.data.data)
          window.open(res.data.data.url, '_blank')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },

    /**
     * 机构入驻/挂靠
     *  + 如果没有机构Id 则打开弹窗用户选择进入挂靠或者入驻
     *  + 如果入驻状态 registerStatus !== 0（未申请） 则进入入驻的详情 ，0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
     *  + 如果挂靠状态 dependStatus !== 0（未申请） 则进入挂靠的详情 ，0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
     */
    orgRegister () {
      if (!this.organization) {
        this.selectIdentityVisible = true
        return
      }

      // 机构角色， 0 - 挂靠关系 / 1 - 法人关系
      const { organizationRole } = this.userInfo
      // 如果是法人关系
      if (organizationRole) {
        // 如果入驻不等于0 (未提交)； 待处理、已通过、和已驳回都进入详情
        // 已驳回会在详情里显示驳回原因和重新申请按钮
        if (this.registerStatus !== globalVar.REGISTER_STATUS_NOTAPPLY) {
          // 机构类别: 0 - 虚拟机构, 1 - 法人机构
          if (this.userInfo.organization.orgType) {
            this.$router.push('/org-audit/corporate/detail')
          } else {
            this.$router.push('/org-audit/uncorporate/detail')
          }
        }
      } else {
        // 进入挂靠详情 同上处理
        if (this.dependStatus !== globalVar.DEPEND_STATUS_NOTAPPLY) {
          this.$router.push(`/member-detail/${this.userInfo.id}`)
        }
      }
    },
    /**
     * 认证成为集成商
     *  + 如果是（法人/非法人）入驻的服务机构则跳转aggregator-admin
     *  + 如果是挂靠入驻的服务机构则跳转
     */
    orgCertification () {
      const { organizationRole, certificationStatus } = this.userInfo
      // 机构角色， 0 - 挂靠关系 / 1 - 法人关系
      if (organizationRole) {
        // certificationStatus: 0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
        if (certificationStatus === globalVar.CERTIFICATION_STATUS_NOTAPPLY) {
          this.$router.push('/org-audit/aggregator-admin/apply')
        } else {
          this.$router.push('/org-audit/aggregator-admin/detail')
        }
      } else {
        // 0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
        if (certificationStatus === globalVar.CERTIFICATION_STATUS_NOTAPPLY) {
          this.$router.push('/org-audit/aggregator-person/apply')
        } else {
          this.$router.push('/org-audit/aggregator-person/detail')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel {
    width: 100%;
    min-width: 1030px;
    min-height: 100%;
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    overflow: auto;
    .panel_header {
      padding: 20px 0;
    }
    .panel_body {
      padding: 10px 0;
      .list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding-left: 40px;
        .item_left {
          display: flex;
          flex: 1;
          align-items: center;
          .title {
            display: flex;
            align-items: center;
            flex: 0 0 200px;
            & > i {
              color: #909399;
              padding-right: 11px;
            }
            & > p {
              font-size: 16px;
              font-weight: bold;
              line-height: 22px;
              color: #000000;
            }
          }
          .desc {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #606266;
          }
        }
        .item_right {
          display: flex;
          align-items: center;
          flex: 0 0 130px;
          margin-left: 10px;
          height: 100%;
          & > a, & > p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3473E6;
            cursor: pointer;
          }
          .icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            color: #C0C4CC;
            cursor: pointer;
            & > i {
              font-size: 22px;
            }
            &:hover {
              color: #CDD0D6;
            }
            &.active {
              color: #3C8CF8;
            }
          }
        }
        &+.list_item {
          margin-top: 10px;
        }
      }
    }
  }
</style>
